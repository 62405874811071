<template>
    <div>
        <div class="offpage_shadow"></div>
        <div class="offpage"></div>
        <!-- ======= Header ======= -->
        <!-- main site header -->
        <header id="header">
            <!-- main site navigation -->
            <div class="container-default">
                <a  title="Homepage"><img src="assets/img/fad-1.png" class="logo" alt="Homepage"></a>
                <a id="hamburger-icon" href="#" title="Menu">
                    <span class="line line-1"></span>
                    <span class="line line-2"></span>
                    <span class="line line-3"></span>
                </a>
                <!-- <div class="language_block">
				<button class="language_button" type="button" data-info="FR" data-toggle="language-dropdown">FR</button>

				<div class="dropdown-pane" id="language-dropdown" data-dropdown data-auto-focus="true">
					<ul class="language_list">
						<li class="active ">
							<a href="home">Français</a>
						</li>
					</ul>
				</div>
			</div> -->
                <a href="http://elkateb-group.com/" title="" target="_blank"
                    class="group_header_link">ElKatebGroup</a>
                <!-- BEGIN: Main menu -->
                <nav class="nav-menu">
                    <ul class="main_menu ">
                        <li class="" @click="$vuetify.goTo('#hero')"><a class="has_submenu ">Accueil</a></li>
                        <li class=""><a href="http://www.fad.tn" target="_blank" title="B2B"
                                class="has_submenu ">B2B</a></li>
                        <li class="" @click="$vuetify.goTo('#services')"><a  title="Service " class="has_submenu ">Service </a></li>
                        <li class="" @click="$vuetify.goTo('#gallery')"><a  title="Marque" class="has_submenu ">Marque</a></li>
                        <li class="" @click="$vuetify.goTo('#clients')"><a  title="Partenaire" class="has_submenu ">Partenaire</a></li>
                        <li class="" @click="$vuetify.goTo('#contact')"><a  title="contact" class="has_submenu ">contact</a></li>
                        <!--	<li class=""><a href="" title="Contact" class="has_submenu ">Contact</a>
					<ul class="menu vertical nested">
						<li><a  title="Formulaire de contact" class="">Contacter</a></li>
						<li><a  title="Interlocuteurs" class="">Demande Partenariat</a></li>
					</ul>
				</li>-->
                    </ul>
                </nav>
            </div>
            <!-- /main site navigation end -->
        </header>
        <!-- End Header -->
    </div>
</template>

<script>
    export default {
        name: 'Nav',
        
    }
</script>

<style scoped>

</style>