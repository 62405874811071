<template>
  <div>
      <!-- ======= Clients Section ======= -->
            <section id="clients" class="clients ">
                <div class="container" data-aos="zoom-in">

                    <div class="section-title">
                        <h2>PARTENAIRES</h2>
                        <p>
                            Les produits de la Société F.A.D sont commercialisés sous différentes gammes et qualités :
                            Origine / 1ère Monte / Adaptable certifié. Ces produits s’adaptent parfaitement à une
                            panoplie de marques de voitures (Européennes, Japonaises, Sud-Coréennes, Américaines…) ainsi
                            qu’à la catégorie poids lourds. </p>
                    </div>
                    <div>
                        <VueSlickCarousel v-bind="settings">
                          
                        <div v-for="image of imgPartenaire" :key="image" ><img :src="image" width="200px"  alt=""></div>
                       
                        </VueSlickCarousel>
                    </div>
                </div>
            </section><!-- End Clients Section -->
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
 name : 'Partenaires',
 components: { VueSlickCarousel },
 data: () => ({
				settings :{
              "infinite": true,
              "centerMode": true,
              "centerPadding": "20px",
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "variableWidth": true,
              "autoplay": true,
              "speed": 2000,
              "autoplaySpeed": 2000,
            },
            imgPartenaire :[
                     "/assets/img/partenairs/Nexus.png",
                    "/assets/img/partenairs/Hitachi.png",
                    "/assets/img/partenairs/HUCO.jpg",
                    "/assets/img/partenairs/bendix.png",
                    "/assets/img/partenairs/BOSCH.png",
                    "/assets/img/partenairs/champion.png",
                    "/assets/img/partenairs/efi.png",
                    "/assets/img/partenairs/elring.png",
                    "/assets/img/partenairs/exedy.png",
                    "/assets/img/partenairs/febi.png",
                    "/assets/img/partenairs/federal.png",
					"/assets/img/partenairs/gates.png",
					"/assets/img/partenairs/hella.png",
					"/assets/img/partenairs/lpr.png",
					"/assets/img/partenairs/luk.png",
					"/assets/img/partenairs/moog.png",
					"/assets/img/partenairs/ntn.png",
					"/assets/img/partenairs/sasic.png",
					"/assets/img/partenairs/skf.png",
					"/assets/img/partenairs/snr.png",
					"/assets/img/partenairs/valeo.png",
					"/assets/img/partenairs/victorreinz.png",
					"/assets/img/partenairs/vika.png",
									
                    ],

      }),
      
}
</script>

<style>

</style>