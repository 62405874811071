<template>
    <div>
        <!-- ======= Footer ======= -->
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-3 col-md-6 footer-info">
                            <h3>F.A.D</h3>
                            <p>
                                16, Rue Anneba-<br> ZI. Ben Arous 2013 Tunisie<br><br>
                                <strong>Téléphone:</strong><a style="color:#fff;" href="tel:+216 79 101 200">+216 79 101
                                    200</a><br>
                                <strong>Email:</strong> <a style="color:#fff;"
                                    href="mailto:contact@fad.com.tn">contact@fad.com.tn</a><br>
                            </p>
                            <div class="social-links mt-3">
                                <!--  <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>-->
                                <a href="https://www.facebook.com/Fournitures-Autos-D%C3%A9riv%C3%A9s-772280469470045/"
                                 target="_blank"   class="facebook"><i class="bx bxl-facebook"></i></a>
                                <!--  <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                                <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                                <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>-->
                                <a href="https://www.youtube.com/channel/UCLmKGGSYzflzdt94YDmwesA/videos"
                                   target="_blank" class="youtube"><i class="bx bxl-youtube"></i></a>

                            </div>
                        </div>

                        <div class="col-lg-2 col-md-6 footer-links">
                            <h4>Liens utiles </h4>
                            <ul>




                                <li @click="$vuetify.goTo('#about')"><i class="bx bx-chevron-right"></i> <a>Accueil</a>
                                </li>
                                <li><i class="bx bx-chevron-right"></i> <a href="http://www.fad.tn" target="_blank"
                                        title="B2B">B2B</a></li>
                                <li @click="$vuetify.goTo('#services')"><i class="bx bx-chevron-right"></i> <a
                                        title="Service ">Service</a>
                                </li>
                                <li @click="$vuetify.goTo('#gallery')"><i class="bx bx-chevron-right"></i> <a
                                        title="Marque">Marque</a>
                                </li>
                                <li @click="$vuetify.goTo('#clients')"><i class="bx bx-chevron-right"></i> <a
                                        href="#clients" title="Partenaire">Partenaire</a></li>
                                <li @click="$vuetify.goTo('#contact')"><i class="bx bx-chevron-right"></i> <a
                                        title="contact">contact</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Nos Services</h4>
                            <ul>
                                <li @click="$vuetify.goTo('#Livreson')"><i class="bx bx-chevron-right"></i>
                                    <a>Livraison</a></li>
                                <li href="http://www.fad.tn"><i class="bx bx-chevron-right"></i> <a>B2B</a></li>
                                <li @click="$vuetify.goTo('#Televente')"><i class="bx bx-chevron-right"></i>
                                    <a>Télévente</a></li>
                                <li @click="$vuetify.goTo('#Articles')"><i class="bx bx-chevron-right"></i>
                                    <a>Articles</a></li>
                                <li @click="$vuetify.goTo('#Garantie')"><i class="bx bx-chevron-right"></i>
                                    <a>Garantie</a></li>
                                <li @click="$vuetify.goTo('#Flotte')"><i class="bx bx-chevron-right"></i> <a>Flotte</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Notre Newsletter</h4>
                            <p></p>
                            <form @submit.prevent="sendEmailNewsletter" role="form" ref="form">
                                <input type="email" name="email" required><input type="submit" value="Souscrire">
                            </form>
<br>
                            <img src="/assets/img/partenairs/Nexus.png" alt="nexus" width="80%">

                        </div>

                    </div>
                </div>
            </div>
            <v-snackbar color="#0c836b" v-model="snackbar" :centered=true :timeout="6000">
                Votre email a été envoyé avec succès

                <template v-slot:action="{ attrs }">
                    <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>

            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>F.A.D IT Team</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer><!-- End Footer -->

        <a href="#" class="back-to-top"><i class="icofont-simple-up" style="color:#fff;"></i></a>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
    export default {
        name: 'Footer',
        data() {
            return {
                snackbar: false,
            }
        },

        methods: {
            async sendEmailNewsletter(e) {
                emailjs.sendForm('service_udsrgr3', 'Newsletter', e.target, 'user_sdBnvuXn4DabMjKhiKWWH')
                    .then(() => {
                        setTimeout(() => (this.snackbar = true), 1)
                        this.$refs.form.reset()

                    }, () => {
                        this.dialog = true

                    });
            }
        }
    }
</script>

<style>

</style>