<template>
  <div>
      <!-- ======= Counts Section ======= -->
            <section class="counts section-bg">
                <div class="container">

                    <div class="row">

                        <div class="col-lg-3 col-md-6 text-center" data-aos="fade-up">
                            <div class="count-box">
                                <i class="icofont-cart-alt" style="color: #0c836b;"></i>
                                <span data-toggle="counter-up">100.000</span>
                                <p>Références</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="count-box">
                                <i class="icofont-users-social" style="color: #0c836b;"></i>
                                <span data-toggle="counter-up">2.000</span>
                                <p>Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="400">
                            <div class="count-box">
                                <i class="icofont-ui-calendar" style="color: #0c836b;"></i>
                                <span data-toggle="counter-up" id="year"> {{year}}</span>
                                <p>
                                    Ans d'Expérience </p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="600">
                            <div class="count-box">
                                <i class="icofont-fast-delivery" style="color: #0c836b;"></i>
                                <span data-toggle="counter-up">10</span>
                                <p>
                                    Lignes de produits</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section><!-- End Counts Section -->
  </div>
</template>
<script>

</script>
<script>


export default {
 name : 'Countable',
 data: () => ({
 year:new Date().getFullYear()-1963,
 })
}
</script>

<style>

</style>