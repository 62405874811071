<template>
<div>
      <!-- ======= Gallery Section ======= -->
            <section id="gallery" >
                <div class="container" data-aos="zoom-in">

                    <div class="section-title">
                        <h2>MARQUES</h2>
                    </div>

        
    <div>
    <VueSlickCarousel v-bind="settings">
      <div v-for="image in imgMarque" :key="image" ><img :src="image" width="200px"  alt=""></div>
                       
    </VueSlickCarousel>
  </div>


                </div>
            </section><!-- End Gallery Section -->
  </div>
  
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
 name : 'Marques',
 components: { VueSlickCarousel },
 data: () => ({
				settings :{
              "infinite": true,
              "centerMode": true,
              "centerPadding": "20px",
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "variableWidth": true,
              "autoplay": true,
              "speed": 2000,
              "autoplaySpeed": 2000,
            },
            imgMarque :[
                    "assets/img/marques/audi.png",
                    "assets/img/marques/chevrolet.png",
                    "assets/img/marques/citroen.png",
                    "assets/img/marques/fiat.png",
                    "assets/img/marques/hyundai.png",
                    "assets/img/marques/isuzu.png",
                    "assets/img/marques/Iveco.png",
                    "assets/img/marques/kia.png",
                    "assets/img/marques/man.png",
                    "assets/img/marques/mazda.png",
                    "assets/img/marques/mitsubishi.png",
                    "assets/img/marques/nissan.png",
                    "assets/img/marques/opel.png",
                    "assets/img/marques/peugeot.png",
                    "assets/img/marques/renault.png",
                    "assets/img/marques/seat.png",
                    "assets/img/marques/skoda.png",
                    "assets/img/marques/toyota.png",
                    "assets/img/marques/volkswagen.png",
                    "assets/img/marques/volvo.png",
                    ],
			}),
}
</script>

<style>

</style>