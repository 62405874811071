<template>
 <div>
    <Nav/>
    <v-app>
    <SlideBanner/>
    <AboutUs/>
    <Countable/>
    <Services/>
    <Marques/>
    <Partenaires/>
    <!-- <Testimonials/> -->
    <Contact/>
    <Footer/>
    </v-app>
 </div>
</template>

<script>
import Nav from './components/Nav';
import SlideBanner from './components/SlideBanner';
import AboutUs from './components/AboutUs';
import Countable from './components/Countable';
import Services from './components/Services';
import Marques from './components/Marques';
import Partenaires from './components/Partenaires';
// import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';

export default {
  name: 'App',

  components: {
    Nav,SlideBanner,AboutUs,Countable,Services,Marques,Partenaires,
    // Testimonials,
    Contact,Footer,

  },

  data: () => ({
    //
  }),
};
</script>
