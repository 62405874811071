<template>
        <!-- ======= Hero Section ======= -->
        <section id="hero">
                    
                        <v-carousel height=auto  >
                            
                            <v-carousel-item v-for="(item,i) in items" :key="i" :src="item.src" 
                                reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
                        </v-carousel>
                   
           
        </section><!-- End Hero -->

</template>

<script>
    export default {
        name: 'SlideBanner',
        data() {
            return {
                items: [{
                        src: 'assets/img/slide/1.png',
                    },
                    {
                        src: 'assets/img/slide/2.png',
                    },
                    {
                        src: 'assets/img/slide/3.png',
                    },

                ],
            }
        },
    }
</script>

<style>

</style>