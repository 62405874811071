<template>
  <div>
      <!-- ======= Services Section ======= -->
            <section id="services" class="services">
                <div class="container">

                    <div class="section-title">
                        <h2>Services</h2>
                    </div>

                    <div class="row">

                        <div class="col-md-6 col-lg-4 wow bounceInUp" data-aos="fade-up" id="Livreson" data-aos-delay="100">
                            <div class="box">
                                <div class="icon" style="background: #f5f9fc;"><i class="icofont-delivery-time"
                                        style="color: #0c836b;"></i></div>
                                <h4 class="title"><a  >Livraison</a></h4>
                                <p class="description">La Société F.A.D s’engage à exécuter vos commandes dans l’immédiat et vous assure la livraison de vos colis 6Jours/7 et 24h/24 sur toute la Tunisie. 
								</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4"  id="B2B" data-aos="fade-up" data-aos-delay="200">
                            <div class="box">
                                <div class="icon" style="background: #f5f9fc;"><i class="icofont-web"
                                        style="color: #0c836b;"></i></div>
                                <h4 class="title"><a  >B2B </a></h4>
                                <p class="description">La Société F.A.D met à la disposition de ces clients un Site B2B qui vous permet de consulter les Pièces disponibles et de passer vos commandes en ligne 7 jours/7 et 24h/24.
								</p>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4" id="Televente" data-aos="fade-up" data-aos-delay="300">
                            <div class="box">
                                <div class="icon" style="background: #f5f9fc;"><i class="icofont-live-support"
                                        style="color: #0c836b;"></i></div>
                                <h4 class="title"><a  >Télévente  </a></h4>
                                <p class="description">Plus de 20 télévendeurs spécialisés dans le domaine des pièces auto à votre disposition pour vous guider et répondre à tous vos besoins.

</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 wow" id="Articles" data-aos="fade-up" data-aos-delay="100">
                            <div class="box">
                                <div class="icon" style="background: #f5f9fc;"><i class="icofont-architecture-alt"
                                        style="color:#0c836b;"></i></div>
                                <h4 class="title"><a  >Articles  </a></h4>
                                <p class="description">La société F.A.D possède le taux de disponibilité le plus élevé sur le marché tunisien pour tout type de véhicule léger. </p>
                            </div>
                        </div>
<!--icofont-tools-->
                        <div class="col-md-6 col-lg-4" id="Garantie" data-aos="fade-up" data-aos-delay="200">
                            <div class=" box">
                                <div class="icon" style="background: #f5f9fc;"><i class="icofont-handshake-deal"
                                        style="color: #0c836b;"></i></div>
                                <h4 class="title"><a  >	Garantie </a></h4>
                                <p class="description">Pour fidéliser ses clients, notre société vous garantit un suivi de clientèle de haute qualité pour répondre à tous vos besoins.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
                            <div class="box">
                                <div class="icon" id="Flotte" style="background: #f5f9fc;"><i class="icofont-fast-delivery"
                                        style="color: #0c836b;"></i></div>
                                <h4 class="title"><a  >	Flotte </a></h4>
                                <p class="description">La société F.A.D met à votre disposition une flotte de 50 véhicules disponibles pour vous assurer un service de livraison de qualité.</p>
                            </div>
                        </div>

                    </div>

                </div>

            </section><!-- End Services Section -->
  </div>
</template>

<script>
export default {
 names :'Services'
}
</script>

<style>

</style>