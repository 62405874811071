<template>
    <div>
        <!-- ======= Contact Section ======= -->
        <section id="contact" class="contact ">
            <div class="container">

                <div class="section-title">
                    <h2>CONTACT</h2>
                    <p>
                        <B style="color:#434847"> La Société  <font style="color:#0c836b">F.A.D </font>LEADER SUR LE
                            MARCHÉ</B><br>

                        Commercialisation en gros des pièces de rechange d’origine, d’équipementiers et adaptables
                        pour véhicules et matériels roulants</p>
                </div>

            </div>

            <div>
                <!--  <iframe style="border:0; width: 100%; height: 350px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe>
      -->
                <iframe style="border:0; width: 100%; height: 350px;"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3196.8656470677515!2d10.236016165334398!3d36.74979584669249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4574aa6ada919d2b!2sELKATEB+GROUP!5e0!3m2!1sfr!2stn!4v1526994933621"
                    frameborder="0" allowfullscreen></iframe>
            </div>

            <div class="container">

                <div class="row mt-5">

                    <div class="col-lg-6">

                        <div class="row">
                            <div class="col-md-12">
                                <div class="info-box">
                                    <i class="bx bx-map"></i>
                                    <h3>Adresse</h3>
                                    <p>16, Rue Anneba- ZI. Ben Arous 2013 Tunisie</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info-box mt-4">
                                    <i class="bx bx-envelope"></i>
                                    <h3>Email </h3>
                                    <p>
                                        <p href="mailto:contact@fad.com.tn">contact@fad.com.tn</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info-box mt-4">
                                    <i class="bx bx-phone-call"></i>
                                    <h3>Contacter nous </h3>
                                    <p>
                                        <p href="tel:+216 79 101 200">+216 79 101 200</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-6">
                        <form @submit.prevent="sendEmail" role="form" class="php-email-form" ref="form">
                            <div class="form-row">
                                <div class="col form-group">
                                    <input type="text" name="user_name" class="form-control" id="name" required
                                        placeholder="Nom" data-rule="minlen:4"
                                        data-msg="Please enter at least 4 chars" />
                                    <div class="validate"></div>
                                </div>
                                <div class="col form-group">
                                    <input type="email" class="form-control" required name="user_email" id="email"
                                        placeholder="Email" data-rule="email" data-msg="Please enter a valid email" />
                                    <div class="validate"></div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col form-group">
                                    <input type="tel" required name="user_tel" class="form-control" id="tel"
                                        placeholder="Téléphone" pattern="[0-9]{8}" data-rule="minlen:8"
                                        data-msg="Please enter at least 8 chars" />
                                    <div class="validate"></div>
                                </div>
                                <div class="col form-group">

                                    <select class="form-control" v-model="select" required id="ville" name="user_ville">

                                        <option selected> Governerat</option>
                                        <option value="Béja">Tunis</option>
                                        <option value="Béja">Béja</option>
                                        <option value="Ben Arous">Ben Arous</option>
                                        <option value="Bizerte">Bizerte</option>
                                        <option value="Gabes">Gabes</option>
                                        <option value="Gafsa">Gafsa</option>
                                        <option value="Jendouba">Jendouba</option>
                                        <option value="Kairouan">Kairouan</option>
                                        <option value="Kasserine">Kasserine</option>
                                        <option value="Kebili">Kebili</option>
                                        <option value="La Manouba">La Manouba</option>
                                        <option value="Le Kef">Le Kef</option>
                                        <option value="Mahdia">Mahdia</option>
                                        <option value="Médenine">Médenine</option>
                                        <option value="Monastir">Monastir</option>
                                        <option value="Nabeul">Nabeul</option>
                                        <option value="Sfax">Sfax</option>
                                        <option value="Sidi Bouzid">Sidi Bouzid</option>
                                        <option value="Siliana">Siliana</option>
                                        <option value="Sousse">Sousse</option>
                                        <option value="Tataouine">Tataouine</option>
                                        <option value="Tozeur">Tozeur</option>
                                        <option value="Zaghouan">Zaghouan</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                    <div class="validate"></div>
                                </div>
                            </div>
                            <div class="form-group">
                                <select class="form-control" id="user_objet" required name="user_objet">
                                    <option value="Dévenir un client" selected>Devenir un client </option>
                                    <option value="Dévenir un fournisseur">Devenir un fournisseur </option>
                                    <option value="Réclamation">Réclamation </option>
                                    <option value="Autre">Autre</option>
                                </select>

                                <div class="validate"></div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" required name="user_subject" id="subject"
                                    placeholder="Sujet" data-rule="minlen:4"
                                    data-msg="Please enter at least 8 chars of subject" />
                                <div class="validate"></div>
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" required name="message" rows="5" data-rule="required"
                                    data-msg="Please write something for us" placeholder="Message"></textarea>
                                <div class="validate"></div>
                            </div>
                            <v-alert v-if="errMsg" dense outlined type="error">
                                {{errMsg}}
                            </v-alert>
                            <div class="text-center"><button type="submit">Envoyer Message</button></div>
                        </form>
                    </div>

                </div>

            </div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="#0c836b" dark>
                    <v-card-text>
                        Attendre, s'il vous plaît
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-snackbar color="#0c836b" v-model="snackbar" :centered=true :timeout="6000">
                Votre message a été envoyé avec succès

                <template v-slot:action="{ attrs }">
                    <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </section><!-- End Contact Section -->
    </div>
</template>

<script>
    import emailjs from 'emailjs-com';
    export default {
        name: 'Contact',
        data() {
            return {
                snackbar: false,
                dialog: false,
                select: "Governerat",
                errMsg: "", 
            }
        },
        watch: {
            dialog(val) {
                if (!val) return

                setTimeout(() => (this.dialog = false), 4000)
            },
        },
        methods: {
            async sendEmail(e) {
                if(this.select == "Governerat"){
                this.errMsg="Merci d'introduire votre Governerat"
                return
                }
                this.errMsg=null
                emailjs.sendForm('service_udsrgr3', 'template_arlqmic', e.target, 'user_ai07GH8PgrupdAz9W4DDe')
                    .then(() => {
                        this.dialog = true
                        setTimeout(() => (this.snackbar = true), 4000)
                        this.$refs.form.reset()

                    }, () => {
                        this.dialog = true

                    });
            }
        }
    }
</script>

<style>

</style>