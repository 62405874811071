<template>

  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">


    <div class="container">

      <div class="row no-gutters ">
        <div class="col-lg-6 d-flex flex-column justify-content-center   video-box p-4">

          <img src="assets/img/FADPresentation.png" class="img-fluid " alt="">
          <div class="text-center">
            <v-dialog v-model="dialog" height="50%" width="50%">
              <template v-slot:activator="{ on, attrs }">

                <a v-bind="attrs" v-on="on" class=" play-btn mb-4"></a>

              </template>

              <v-card>
                <video width="100%" controls>
                  <source src="assets/Video/Presentation FAD.mp4" type="video/mp4">
                  Your browser does not support HTML video.
                </video>
              </v-card>
            </v-dialog>
          </div>

        </div>

        <div class="col-lg-6 d-flex flex-column justify-content-center about-content">

          <div class="section-title">
            <h2>A-props de nous</h2>
            <p>La société Fournitures Autos & Dérivés (F.A.D) est le leader des distributeurs spécialisé dans le
              commerce en gros des pièces multimarques (origine ; équipementiers et adaptables)
              pour Véhicules et Poids lourds. Depuis sa création en 1963, notre société a su se positionner sur le
              marché en partenariat avec les marques Stratégiques qui lui ont fait entièrement
              confiance et en réalisons une évolution de 2 chiffres d’une année à une autre.
            </p>
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <v-card color>
              <div class="d-flex flex-no-wrap ">
                <v-avatar class="ma-3" size="125" tile>
                  <v-icon size="100" color="#0c836b">
                    mdi-phone-in-talk
                  </v-icon>
                </v-avatar>
                <div>
                  <v-card-title color="#0c836b" class="">Salle de vente</v-card-title>

                  <v-card-subtitle>Plus que 20 télévendeurs disponibles pour répondre à vos besoins</v-card-subtitle>

                  <v-btn color="blue-grey" class="ma-2 white--text ml-2 mb-5" href="assets/PDF/Liste Télé.pdf"
                    target="_blank">
                    Liste des télévendeurs
                    <v-icon right dark>
                      mdi-cloud-upload
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>


            <v-divider></v-divider>

            <v-card color>
              <div class="d-flex flex-no-wrap ">
                <v-avatar class="ma-3" size="125" tile>
                  <v-icon size="100" color="#0c836b">
                    mdi-web
                  </v-icon>
                </v-avatar>
                <div>
                  <v-card-title color="#0c836b" class="">B2B web</v-card-title>

                  <v-card-subtitle>Une interface de vente en ligne disponible 24/24 et 7j/7j </v-card-subtitle>

                  <v-btn color="blue-grey" class="ma-2 white--text ml-2 mb-5" href="http://www.fad.tn" target="_blank">
                    acceder au site
                    <v-icon right dark>
                      mdi-web
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>

        </div>
      </div>

    </div>
  </section><!-- End About Us Section -->

</template>

<script>
  export default {
    name: 'AboutUs',
    data: () => ({
      dialog: false,
    }),

  }
</script>

<style>

</style>